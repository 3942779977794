export default {
    methods: {
        request: function (action, dataForm, extra) {
            const self = this;
            let isLoading = true;
            let formData = null;
            let hasTimeout = false;
            let diplayModalError = true;            

            if (typeof extra != "undefined") {
                hasTimeout =
                    Object.keys(extra).length && extra.timeout > 0
                        ? true
                        : false;
                diplayModalError =
                    Object.keys(extra).length && extra.showModalError
                        ? true
                        : false;
                isLoading = Object.keys(extra).length && extra.loading
                        ? true
                        : false;
            }

            if (typeof dataForm == "undefined") {
                formData = new FormData();
            } else {
                formData = dataForm;
            }

            return new Promise(function (resolve, reject) {                                
                formData.append("session", self.$store.getters.session);
                formData.append("source", self.$store.getters.query.sourceid ?? null);

                if (isLoading) {
                    self.$store.dispatch("loading", 1);
                }

                const options = {
                    method: "post",
                    headers: new Headers({
                        "X-WINTER-REQUEST-HANDLER": action,
                        "X-WINTER-REQUEST-PARTIALS": "",
                        "X-Requested-With": "XMLHttpRequest",
                        "X-WINTER-REQUEST-FLASH": 1,
                    }),
                    body: formData,
                };

                // define timeout para a requisicao
                if (hasTimeout) {
                    let controller = new AbortController();
                    options.signal = controller.signal;
                    const clock = setTimeout(() => {
                        controller.abort();
                        clearTimeout(clock);
                    }, extra.timeout);
                }

                fetch(window.location.href, options)
                    .then(function (response) {
                        self.$store.dispatch("loading", 0);

                        response.json().then((json) => {
                            if (response.ok) {
                                resolve(json);
                            } else {
                                self.$store.dispatch("setError", json.message);
                                if (diplayModalError) {
                                    // self.$root.openModal("#modal-error");
                                }
                                reject(json);
                            }
                        });
                    })
                    .catch(function (error) {
                        console.log(error.name);
                        console.log("Request Error", error);
                        if (
                            "object" == typeof error &&
                            error.name != "AbortError"
                        ) {
                            error.json().then((err) => {
                                console.error(err);
                                self.$store.dispatch("setError", err.message);
                                if (diplayModalError) {
                                    // self.$root.openModal("#modal-error");
                                }
                            });
                        }
                        self.$store.dispatch("loading", 0);

                        reject(error);
                    });
            });
        },
        getDevice: function () {
            var userAgent =
                navigator.userAgent || navigator.vendor || window.opera;

            if (/windows phone/i.test(userAgent)) {
                return "windows_phone";
            }

            if (/android/i.test(userAgent)) {
                return "android";
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "ios";
            }

            return "web";
        },
    },
};
