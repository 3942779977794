<template>
  <div class="content-home-banner">
    <div class="content-home-banner-bg"></div>
    <div class="content-home-banner-text" v-if="this.$route.name == 'payment-choice' && $attrs.display">
      <p>
        Confirme o detalhe da sua conta e utilize os meios de pagamento disponíveis abaixo.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>