<template>
  <div>
    <main class="payment">     
      <Header /> 
      <section class="content-payment-failure">
        <div class="content-payment-itens container">          
          <template v-if="transaction">
            <a class="content-payment-itens-header w-100" @click="cancelEvent({redirect: 'payment/' + $store.getters.query.orderid})" style="margin-top:10px;">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.3"
                height="19.914"
                viewBox="0 0 10.3 19.914"
              >
                <g transform="translate(10.3 19.914) rotate(180)">
                  <g transform="translate(33.623 70.53) rotate(180)">
                    <path
                      d="M9.2,20a.908.908,0,0,1-.7-.3L.3,10.9a.853.853,0,0,1,0-1.3L8.5.4A1.071,1.071,0,0,1,9.9.3,1.071,1.071,0,0,1,10,1.7L2.4,10.2,10,18.4a.967.967,0,0,1,0,1.4A2.354,2.354,0,0,1,9.2,20Z"
                      transform="translate(23.323 50.53)"
                      fill="#273356"
                    />
                  </g>
                </g>
              </svg>
              {{ $t("btn.cancel") }}
            </a>
          </template>          

          <!--- IFRAME -->
          <template v-if="transaction && transaction.external_payment_token">         
            <form target="paybyrdFrame" id="paybyrdForm" :action="gateways[env] + '/v1/hostedform'" method="post">
              <input type="hidden" name="orderId" :value="transaction.external_payment_token"/>
              <input type="hidden" name="redirectUrl" :value="fetchUrl('payment-paybyrd-redirect')"/>
              <input type="hidden" name="failureRedirectUrl" :value="fetchUrl('payment-paybyrd-failure')"/>
              <input type="hidden" name="culture" value="pt-PT" />
              <input type="hidden" name="showCancelButton" value="false" />
            </form>
    
            <iFrame class="iframe-paybyrd" name="paybyrdFrame" id="paybyrdFrame" scrolling="no"></iFrame>            
          </template>                           
          <!--- ./IFRAME -->
          <template v-if="activeLoading">
              <Loading />
          </template>
        </div>
      </section>
      <template v-if="notification.type">                            
        <generic-notification v-bind:notification="notification" />   
      </template>
    </main>    
  </div>
</template>

<script>
// import axios from "axios";
import Header from "@/components/header/Header.vue";
import GenericNotification from '@/components/GenericNotification.vue';
import Loading from "@/components/Loading.vue";
export default {
  name: "payment-paybyrd",
  components: {
    GenericNotification,
    Header,
    Loading
  },
  mounted() {        
    // configura no VUEX a forma de pagamento da transacao: paybyrd
    this.setupMethod();
    // verifica qual o ambiente da aplicação
    this.checkEnvironment();    
    // recupera os dados do pagamento
    this.fetchPayment();
  },
  updated: function () {
    this.$nextTick(function () {
        // vizualizacao do frame da PAYBYRD
        this.initFrame();
    })
  },
  methods: {
    // ? atualizacao da forma de pagamento escolhida pelo cliente    
    setupMethod: function(){
      const self = this;
      // recupera os dados de facturacao do VUEX      
      let billingData = self.$store.getters.billing ?? {};      
      // define a forma de pagamento paybyrd ( 4 ) no VUEX
      billingData['payment_type_id'] = 4;
      // atualiza os dados de facturacao do VUEX
      self.$store.dispatch("setBilling", billingData);      
    }, 

    // ? renderiza o ifram do Paybyrd
    initFrame: async function(){      
      const self = this; 
      if(self.transaction && Object.keys(self.transaction).length && self.transaction.status_payment_id == 1){                 
        document.getElementById("paybyrdForm").submit();      
      }            
    },  

    // ? recupera os dados da transacao
    fetchPayment: function(){
        const self = this;
        let form = new FormData;
        form.append('payment_type', 'paybyrd');
        form.append('orderid', self.$store.getters.query.orderid ?? '');
        form.append('client_doc', self.$store.getters.billing.doc ?? '');
        form.append('client_terms', self.$store.getters.billing.accept_terms ?? '');
        form.append('client_email', self.$store.getters.billing.email ?? '');
        form.append('client_name', self.$store.getters.billing.name ?? '');
        form.append('client_phone', self.$store.getters.billing.phone ?? '');
        self.$root.request('biipPayments::onCreate', form, {loading: false})
        .then( response => {           
            if( response.success ){
                self.transaction = response.data;                
                self.$store.dispatch("setProcess", response.data.transaction_ref);                
            }else{
                self.notification.type = 'error';                
                self.notification.msg = response.error;
            }
        })
        .catch( function(error){
          self.notification.type = 'error';
          self.notification.msg = 'falha ao criar o pagamento';
        })
        .finally(()=>{
            this.activeLoading = false;
        });
    }, 

    // ? define as urls de redirects para o iframe da PAYBYRD
    fetchUrl: function(sufix){
      return location.origin + '/' + sufix;
    },

    // ? cancela a transacao no backoffice do biip
    cancelEvent: async function (configs = {}) {
        const self = this;
        let formData = new FormData();
        // envia o numero do processo a ser cancelada            
        formData.append("payment_ref", self.$store.getters.process);
        formData.append('payment_type', 'paybyrd');
        // aciona o cancelamento da transacao
        let resp = await self.$root
            .request("biipPayments::onCancelByRef", formData)
            .then((response) => {
                // reseta o processo de pagamento atual no VUEX
                self.$store.dispatch("setProcess", null);
                self.progress = null;
                // redireciona para a tela de escolha do metodo de pagamento
                if (Object.keys(configs).length) {
                    if (configs.unblock) {
                        self.setTableUnblocked();
                    }

                    if (configs.redirect.length) {
                        self.$router.push({ path: configs.redirect });
                    }
                }
            });
    },
    
    // ? verifica o ambiente de execucao
    checkEnvironment(){
        const self = this;
        let form = new FormData;
        self.$root.request('biipPayments::onCheckEnv', form, {loading: false})
        .then( response => {           
            if( response.success ){
                self.env = response.data;                
            }
        })
        .catch( function(error){
          console.log('error');
          console.log(error);
        });
    }
  },
  data: function () {
    return {
      notification:{
          type: "",
          title: "",
          msg: ""
      },
      transaction: null,
      activeLoading: true,
      env: 'dev',
      gateways: {
        'dev': 'https://gatewaysandbox.paybyrd.com',
        'prd': 'https://gateway.paybyrd.com'
      }
    };
  },
  beforeDestroy() {}  
};
</script>

<style lang="scss" scoped>
.content-payment-failure {

    background:#eaeaea;
    // position: fixed;
    // width: 100%;
    // height: 100%;
    overflow: auto;
    .content-payment-itens{
      height: 100%;
    }
    .content-text-element h2,
    .content-text-element p {
        color: #f7c111;
    }
}
.iframe-paybyrd {
  width: 1px !important;
  min-width: 100% !important;
  *width: 100% !important;
  height: 800px; 
  margin: 0; 
  border: 0;
}
</style>
