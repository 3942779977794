import store from '@/store/index.js';

export default function money (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-PT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    
    var config = store.getters.configs;
    var moneySimbol = config && Object.keys(config).includes('moeda') ? config.moeda : null;
    var money = formatter.format(value);
    if (moneySimbol){
        money.replace('€', moneySimbol);
    }
    return money;
}