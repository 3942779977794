<template>
  <div>
    <main class="payment-situation">    
      <Header /> 
      <section class="content-payment-situation">
        <div class="content-payment-situation-itens container">          
          <!-- SEÇAO PAGAMENTO CANCELADO -->
          <div class="content-text">
            <div class="content-text-image">
              <img
                class="img-fluid"
                src="../assets/img/payment/pagamento-cancelado.png"
                alt="pagamento cancelado"
                title="pagamento cancelado"
              />
            </div>
            <div class="content-text-element">
              <h2>{{ $t('payment_cancelled.title') }}</h2>
              <p>{{ $t('payment_cancelled.message') }}</p>
            </div>
            <div class="content-text-btn">
              <!-- <router-link to="/home" class="content-text-btn-close">{{ $t('btn.close') }}</router-link> -->
              <router-link :to="{ name: 'payment-choice', params: { id: $store.getters.query.orderid }}" class="content-text-btn-opinion">{{ $t('btn.repeat_payment') }}</router-link>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";

export default {
  name: "payment-cancelled",
  components: {
    Header
  }  
};
</script>
