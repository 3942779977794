<template>
    <!-- style="--main-color: initial" -->
    <div id="app">		
        <main class="content-main">
            <router-view />
        </main>

        <!--- BACKDROP -->
        <div class="backdrop_bar d-none ok"></div>
        <!-------------------->        		

		<!--- LOADING -->
		<div class="content-loading d-none">
			<div class="backdrop-loader">
				<div class="loader"></div>
			</div>
		</div>
		<!--------------------> 
    </div>
</template>

<style lang="scss">
  @import "./assets/scss/generic/mixins.scss";
  @import "./assets/scss/generic/fonts.scss";
  @import "./assets/scss/generic/utilities.scss";

  :root {
      --main-color: #cc0000;
      --main-color-light: #f21a35;
      --main-color2: #d9b48f;
      --main-color3: #c6afa0;
      --main-color4: #5e4f47;
      --red: #cc0000;
      --red-2: #f21a35;
      --green: #4ab900;
      --white: #fff;
      --black: #000;
      --blue: #19d1cd;
      --blue-dark: #132148;
      --blue-dark-2: #152147;
  }
  html,
  body {
      margin: 0;
      width: 100%;
      color: #687188;
  }
  #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
  }
  #nav {
      padding: 30px;
      a {
          font-weight: bold;
          color: #2c3e50;

          &.router-link-exact-active {
              color: #42b983;
          }
      }
  }
  .content-main {
      background: #f6fafb;
  }
  .swiper-button-next,
  .swiper-button-prev {
      outline: none !important;
      &::after {
          content: none;
      }
  }
  .swiper-container .swiper-button-next,
  .swiper-container .swiper-button-prev {
      background: 0;
      border: 0;
  }
  .overflow-hidden {
      overflow: hidden;
  }
  .backdrop_bar {
      position: fixed;
      z-index: 2;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
  }
  a {
      text-decoration: none !important;
  }
  button {
      outline: none !important;
  }
  .content-payment {
	padding: 30px 0px 140px 0px;
	&-itens {
		&-header {
			font-size: 30px;
			font-family: 'Font Black';
			color: var(--blue-dark) !important;
			transition: 500ms;
			display: inline-flex;
			align-items: center;
			margin-bottom: 30px;
			line-height: 1.2;
			@media (max-width: 359px){
				font-size: 24px;
			}
			svg {
				margin-right: 20px;
			}
		}
		&-body {
			.content-element {
				&:first-child{
					margin-bottom: 55px;
				}
				&-header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 20px;
					p {
						font-size: 16px;
						font-family: 'Font Black';
						color: #5CA3D8;
						margin-bottom: 0;	
						line-height: 1;
						span {
							font-family: 'Font Book';
						}
					}
				}
				.content-inputs {
					margin-bottom: 20px;
					input {
						height: 55px;
						width: 100%;
						border-radius: 10px;
						border: 0;
						box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
						padding-left: 20px;
						font-size: 18px;
						font-family: 'Font Book';
						color: var(--blue-dark);
						&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
							color: #D4D4D4;
						}
						&::-moz-placeholder { /* Firefox 19+ */
							color: #D4D4D4;
						}
						&:-ms-input-placeholder { /* IE 10+ */
							color: #D4D4D4;
						}
						&:-moz-placeholder { /* Firefox 18- */
							color: #D4D4D4;
						}
					}
					&-select {
						position: relative;
						select {
							height: 55px;
							width: 100%;
							border-radius: 10px;
							border: 0;
							box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
							padding-left: 20px;
							font-size: 18px;
							font-family: 'Font Book';
							color: var(--blue-dark);
							appearance: none;
							-moz-appearance: none;
							-webkit-appearance: none;
							outline: none;
						}
						svg {
							position: absolute;
							right: 20px;
							top: 50%;
							transform: translateY(-50%);
							pointer-events: none;
						}
					}
				}
				&-account {
					padding: 25px 20px;
					background: var(--white);
					border: 1px solid #ECF7FF;
					border-radius: 10px;
					margin-bottom: 50px;
					&-itens {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 22px;
						p {
							font-size: 16px;
							font-family: 'Font Book';
							color: #707070;
							margin-bottom: 0;
						}
					}
					&-total {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-top: 20px;
						border-top: 1px solid #ECF7FF;
						p {
							font-size: 16px;
							font-family: 'Font Black';
							color: #132148;
							margin-bottom: 0;
						}
					}
				}
				&-btns {
					.btn-mbway {
						height: 70px;
						width: 100%;
						background: var(--white);
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 10px;
						border: 0;
						box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
						margin-bottom: 20px;
						transition: 500ms;
						&:hover,&:focus {
							cursor: pointer;
							opacity: 0.8;
						}
					}
					.btn-visa-mastercard {
						height: 70px;
						width: 100%;
						background: var(--white);
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 10px;
						border: 0;
						box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
						transition: 500ms;
						&:hover,&:focus {
							cursor: pointer;
							opacity: 0.8;
						}
						img {
							max-height: 40px;
						}
					}
				}
			}
		}
	}
}
.btn-payment {
	position: fixed;
	left: 0;
	bottom: 50px;
	height: 65px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-family: 'Font Black';
	color: var(--white) !important;
	background: #62C55E;
	border: 0;
	transition: 500ms;
	z-index: 2;
	&:hover, &:focus {
		cursor: pointer;
	}
}
.content-payment-situation {
	height: 100vh;
	padding: 30px 0px 100px 0px;
	background: #152147;
	&-itens {
		.content-text {
			text-align: center;
			&-image {
				margin-bottom: 20px;
				text-align: center;
			}
			&-element {
				margin-bottom: 30px;
				h2 {
					font-size: 40px;
					font-family: 'Font Black';
					color: var(--white);
					margin-bottom: 10px;
					line-height: 1;
				}
				p {
					font-size: 15px;
					font-family: 'Font Roman';
					color: var(--white);
					margin-bottom: 0;
					width: 275px;
					margin: 0 auto;
				}
			}
			&-btn {
				&-close {
					height: 55px;
					width: 100%;
					background: transparent;
					font-size: 20px;
					font-family: 'Font Book';
					color: var(--white) !important;
					border: 2px solid var(--white);
					border-radius: 10px;
					box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
					margin-bottom: 25px;
					transition: 500ms;
					display: flex;
					justify-content: center;
					align-items: center;
					&:hover,&:focus {
						cursor: pointer;
					}
				}
				&-opinion {
					height: 55px;
					width: 100%;
					background: #43AAFD;
					font-size: 20px;
					font-family: 'Font Black';
					color: var(--white) !important;
					border: 0;
					border-radius: 10px;
					box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
					transition: 500ms;
					display: flex;
					justify-content: center;
					align-items: center;
					&:hover,&:focus {
						cursor: pointer;
					}
				}
			}
		}
	}
}
.content-order-placed {
	padding: 30px 0px 100px 0px;
	background: #152147;
	height: 100vh;
	&-itens {
		.content-text {
			text-align: center;
			&-image {
				margin-bottom: 40px;
				text-align: center;
				@media (max-width: 359px){
					max-width: 200px;
					margin: 0 auto 40px auto;
				}
			}
			&-element {
				margin-bottom: 30px;
				h2 {
					font-size: 40px;
					font-family: 'Font Black';
					color: var(--white);
					margin-bottom: 10px;
					line-height: 1;
				}
				p {
					font-size: 15px;
					font-family: 'Font Roman';
					color: var(--white);
					margin-bottom: 0;
					width: 275px;
					margin: 0 auto;
				}
			}
			&-btn {
				&-close {
					height: 55px;
					width: 100%;
					background: transparent;
					font-size: 20px;
					font-family: 'Font Book';
					color: var(--white) !important;
					border: 2px solid var(--white);
					border-radius: 10px;
					box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
					margin-bottom: 25px;
					transition: 500ms;
					display: flex;
					justify-content: center;
					align-items: center;
					&:hover,&:focus {
						cursor: pointer;
					}
				}
				&-opinion {
					height: 55px;
					width: 100%;
					background: #43AAFD;
					font-size: 20px;
					font-family: 'Font Black';
					color: var(--white) !important;
					border: 0;
					border-radius: 10px;
					box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
					transition: 500ms;
					display: flex;
					justify-content: center;
					align-items: center;
					&:hover,&:focus {
						cursor: pointer;
					}
				}
			}
		}
	}
}
.btn-continue {
	height: 55px;
	width: 100%;
	max-width: 345px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #43AAFD;
	border: 1px solid #43AAFD;
	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	border-radius: 10px;
	font-size: 20px;
	font-family: 'Font Book';
	color: var(--white) !important;
	transition: 500ms;
	margin-top: 50px;
	&:hover, &:focus {
		cursor: pointer;
		background: var(--white);
		color: #43AAFD !important;
	}
}
.content-payment-failure {
	background:#eaeaea;
	// position: fixed;
	width: 100%;
	height: 100%;
	overflow: auto;
	.content-text-element h2, .content-text-element p{
		color: #132148;
	}
	.content-text-btn-close {
		background: #132148;
		border: 1px solid #132148;
	}
}
.modal-clerk {
	.modal-content {
		border: 0;
		border-radius: 0;
		box-shadow: none;
		width: 90%;
		margin: 0 auto;
	}
	.modal-body {
		padding: 30px 25px;
		&-icon {
			text-align: center;
			margin-bottom: 40px;
		}
		&-text {
			margin-bottom: 30px;
			text-align: center;
			p {
				font-size: 20px;
				font-family: "Font Black";
				color: var(--black);
				margin-bottom: 0;
			}
		}
		&-btns {
			text-align: center;
			&-confirm {
				width: 100%;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #8bcb61;
				border: 1px solid #8bcb61;
				border-radius: 10px;
				font-size: 20px;
				font-family: "Font Book";
				color: var(--white) !important;
				transition: 500ms;
				margin-bottom: 30px;
				&:hover,
				&:focus {
					cursor: pointer;
					background: var(--white);
					color: #8bcb61 !important;
				}
			}
			&-close {
				font-size: 20px;
				font-family: "Font Book";
				color: var(--blue-dark) !important;
				transition: 500ms;
				background: 0;
				border: 0;
				&:hover,
				&:focus {
					cursor: pointer;
					opacity: 0.8;
				}
			}
		}
	}
}
.content-loading {
  .backdrop-loader {
    position: fixed;
    top: 140px;
    height: calc(100% - 140px);
    width: 100%;
    z-index: 1020;
    background-color: #00205C;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    .loader {
      border: 16px solid #fff;
      border-top: 16px solid #43aafd;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}
</style>
<script>
export default {	
    data() {}
};
</script>
