<template>
  <div class="content-mensage-invoice">
		<div class="content-mensage-invoice-text">
			<p>
				Se desejar a fatura em papel, por favor solicite ao funcionário ou dirija-se ao balcão.
			</p>
		</div>
		<div class="content-mensage-invoice-icon">
			<img class="img-fluid" src="@/assets/img/recibo.png" alt="image recibo" title="image recibo"/>
			<img class="img-fluid" src="@/assets/img/recibo-2.png" alt="image recibo" title="image recibo"/>
		</div>
		<div class="wave-bottom"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.content-mensage-invoice {
	position: relative;
	padding: 50px 20px;
	display: block;
	background: var(--white);
	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	text-align: center;
	max-width: 250px;
	margin: 30px auto;
	.wave-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 15px;
		&::before{
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			background-repeat: repeat;
			height: 10px;
			background-size: 20px 20px;
			background-image:
			radial-gradient(circle at 10px -5px, transparent 12px, var(--blue-dark) 13px);
		}
		&::after{
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			background-repeat: repeat;
			height: 15px;
			background-size: 40px 20px;
			background-image:
				radial-gradient(circle at 10px 15px, var(--blue-dark) 12px, transparent 13px);
		}
	}
	&-text {
		p {
			font-size: 14px;
			font-family: 'Font Black';
			color: var(--blue-dark);
			margin-bottom: 0;
		}
	}
	&-icon {
		margin-top: 20px;
		img {
			max-width: 50px;
		}
	}
}
</style>