<template>
    <div>
        <main class="payment-situation">
            <section class="generic-content">
                <div class="content-payment-situation-itens container">                              
                    <div class="content-text">
                        <div class="content-text-image">

                        <img 
                            v-if="$attrs.notification.type == 'info'"
                            class="img-fluid"
                            src="../assets/img/payment/pagamento-sucesso.png"
                            alt=""
                            title=""
                            width="150"
                        />

                        <img 
                            v-if="$attrs.notification.type == 'error'"
                            class="img-fluid"
                            src="../assets/img/payment/pagamento-cancelado.png"
                            alt=""
                            title=""
                            width="150"
                        />
                        </div>
                        <div class="content-text-element">
                            <h2>{{ this.$attrs.notification.title }}</h2>
                            <p>{{ this.$attrs.notification.msg }}</p>
                        </div>
                        <div class="content-text-btn"></div>
                    </div>
                </div>
                <mensage-invoice v-if="isApprovedView" />                
            </section>
        </main>
    </div>
</template>

<script>
import MensageInvoice from '@/components/MensageInvoice.vue';
export default {
  name: "genericn",
  components: {
    MensageInvoice
  },
  computed: {
      isApprovedView: function() {
          return this.$route.name == 'payment-approved';
      }              
  }
};
</script>

<style scoped>
.generic-content {
    height: calc(100% - 140px);
    padding: 30px 0 100px 0;
    background: #152147;
    position: fixed;
    width: 100%;
    top: 140px;
    left: 0;    
}
.generic-content .content-payment-failure .content-text-element p, .generic-content .content-payment-failure .content-text-element h2 {
    color: rgb(255, 255, 255) !important;
}
</style>
