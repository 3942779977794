<template>
  <b-modal id="modal-terms-conditions" centered class="modal-terms-conditions">
    <div class="modal-terms-conditions-itens">
			<button class="modal-terms-conditions-itens-close" @click="$bvModal.hide('modal-terms-conditions')">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.038" height="13.547" viewBox="0 0 12.038 13.547"><path d="M5.086,1.647.713-4.313-3.642,1.647H-5.287L-.139-5.32-4.958-11.9h1.645L.771-6.365,4.835-11.9H6.383l-4.8,6.541L6.751,1.647Z" transform="translate(5.287 11.9)" fill="#fff"/></svg>
			</button>
			<div class="modal-terms-conditions-itens-text">
				<h2>Termos e Condições</h2>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus mi ante, vehicula vitae quam at, dapibus facilisis dui. Cras lorem sem, volutpat vel ultrices eu, ultricies in ante. Etiam blandit mi semper purus viverra iaculis. Morbi metus ex, blandit in neque id, bibendum consequat enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec nulla ipsum. Nullam justo lacus, mollis nec semper sed, tempor vitae nisl. Pellentesque sit amet vulputate lorem. Donec placerat justo sem. Aliquam ultrices placerat felis non dictum. Suspendisse nec enim lectus. Cras nulla turpis, tristique blandit felis et, feugiat volutpat neque. Nullam ornare, arcu non porta tincidunt, sapien nisl mattis odio, nec porttitor neque mauris sed libero. Donec vehicula fringilla nisi, sit amet egestas mauris. Curabitur auctor ullamcorper magna, sit amet semper elit rutrum ac. Mauris efficitur finibus finibus. Proin justo tellus, vehicula et varius quis, commodo eget libero. Etiam faucibus faucibus arcu eget malesuada. Phasellus ultrices ut eros ultricies varius. Cras non neque id magna luctus gravida vitae et velit. Morbi auctor, dolor ac aliquet pharetra, neque diam ultrices purus, in mollis dolor justo sit amet lacus. Sed volutpat fermentum auctor. Ut blandit gravida erat et scelerisque. Praesent in blandit urna. Cras eget mauris quis orci dictum imperdiet nec sed magna. Aenean pulvinar viverra posuere. Nulla facilisi.</p>
			</div>
			<div class="modal-terms-conditions-itens-btns">
				<button class="btn-confirm" @click="$bvModal.hide('modal-terms-conditions')">
					Confirmar
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
}
</script>

<style lang="scss">
    #modal-terms-conditions {
        .modal-header, .modal-footer {
            display: none;
        }
        .modal-content {
            border-radius: 20px;
            box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            border: 0;
        }
        .modal-body {
            padding: 40px 20px 20px 20px;
        }
    }
    .modal-terms-conditions {
        &-itens {
            &-close {
                position: absolute;
                right: -4px;
                top: -7px;
                height: 38px;
                width: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--blue-dark);
                border: 0;
                border-radius: 100%;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                transition: 500ms;
                &:hover,&:focus {
                    opacity: 0.8;
                }
            }
            &-text {
                text-align: left;
                h2 {
                    font-size: 22px;
                    font-family: 'Font Black';
                    color: var(--blue-dark);
                    margin-bottom: 38px;
                    span {
                        display: block;
                    }
                }
                p {
                    font-size: 16px;
                    font-family: 'Font Book';
                    color: var(--blue-dark);
                    margin-bottom: 0;
                }
            }
            &-btns {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 38px;
                .btn-confirm {
                    width: 180px;
                    height: 50px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #152038;
                    border: 1px solid #152038;
                    border-radius: 5px;
                    font-size: 20px;
                    font-family: 'Font Black';
                    color: var(--white);
                    transition: 500ms;
                    &:hover,&:focus {
                        cursor: pointer;
                        background: var(--white);
                        color: #152038;
                    }
                    @media (max-width: 767px){
                        font-size: 16px;
                        height: 40px;
                    }
                }
            }
        }
    }
</style>