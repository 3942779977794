<template>
  <div>
    <main class="payment-situation"> 
      <Header /> 
      <template>
          <generic-notification v-bind:notification="notification" />          
      </template>
    </main>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import GenericNotification from '@/components/GenericNotification.vue';

export default {
  name: "payment-approved",
  components: {    
    Header,
    GenericNotification    
  },
  data: function(){
    return {
      progress: null,
      doc: null,
      timer:null,
      notification:{
          type: "info",
          title: "Pagamento Concluído",
          msg: "Obrigado por utilizar o Biip Pay."
      }
    }
  },
  methods: {},
};
</script>
