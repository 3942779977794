<template>
    <section class="content-payment-situation content-payment-failure">
        <div class="content-payment-situation-itens container">
            <!-- ESPERA DE STATUS -->
            <template v-if="progress">
                <div
                    class="text-center"
                    style="margin-top: 150px; margin-bottom: 25px"
                >
                    <img src="../assets/img/loading-payment.gif" alt="loading payment" style="height: 190px" />
                </div>

                <div class="content-text">
                    <div class="content-text-element">
                        <h2>{{ waiting.title }}</h2>
                        <!--- <p> Estamos a processar o pagamento. </p> -->
                        <p>{{ waiting.message }}</p>
                        <template v-if="expiresIn > 0 && expiresIn <= 40">
                            <p>
                                {{ $t("payment_situation_msg.expiresIn") }}
                                <strong>{{ expiresIn }}</strong> s.
                            </p>
                        </template>
                    </div>
                </div>
            </template>
            <!-- ./ESPERA DE STATUS -->

            <!-- SEÇAO PAGAMENTO -->
            <template v-else-if="Object.keys($store.getters.error).length > 0">
                <div class="content-text">
                    <div class="content-text-image">
                        <img
                            class="img-fluid"
                            src="../assets/img/payment/pagamento-cancelado.png"
                            alt="pagamento iniciado"
                            title="pagamento iniciado"
                        />
                    </div>

                    <div class="content-text-element">
                        <template
                            v-if="
                                typeof $store.getters.error == 'object' &&
                                Object.keys($store.getters.error).length > 0
                            "
                        >
                            <h2>{{ $t("unvailable.system_down") }}</h2>
                            <p>{{ $t("unvailable.system_down_msg") }}</p>
                        </template>
                        <template v-else>
                            <h2>{{ waiting.title }}</h2>
                            <p>{{ waiting.message }}</p>
                        </template>
                    </div>

                    <div class="content-text-btn">
                        <router-link
                            to="/payment-choice"
                            class="content-text-btn-opinion btn-unvailable"
                            >{{ $t("btn.repeat_payment") }}</router-link
                        >
                    </div>
                </div>
            </template>
            <!-- SEÇAO PAGAMENTO -->
        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "payment-paybyrd-redirect",
    components: {},
    mounted() {
        const self = this;        
        // recupera os dados da transacao vinculada ao UUID enviado pela PAYBYRD
        self.fetchPayment();

        // timer de 1,5 min, para que o utlizador inicie o pagamento
        self.setPaymentTimeout();   
    },
    methods: {
       // para todas as datas : 2022-01-01T01:01:01Z
        fetchPayment() {
            const self = this;
            try {
                let form = new FormData();
                form.append("payment_token", this.$route.query.orderId ?? "");
                form.append('payment_type', 'paybyrd');
                self.$root
                    .request("biipPayments::onFetch", form)
                    .then((response) => {                                 
                        // armazena dados do pagamento atual               
                        self.progress = response.data;   

                        // define-se 3min para o pagamento ser comcluido
                        self.timecountdown = 90;                                             

                        // salva a referencia da transacao paybyrd
                        if (self.progress && self.progress.transaction_ref) {
                            self.$store.dispatch("setProcess",self.progress.transaction_ref);
                        }
                        
                        if (self.progress) {
                            // efetua tratativa a ordem de pagamento atual
                            self.handleStateProgress(self.progress);
                        } else {
                            // nenhum processo de pagamento encontrado
                            self.$router.push({ name: "payment-choice" });
                        }

                        if (self.timecountdown > 0) {
                            self.startCountdown();
                        }
                    });
            } catch (err) {
                console.error(err);
            }
        },

        getProgress() {
            const self = this;

            self.countStatePayment++;

            return new Promise(function (resolve, reject) {
                let formData = new FormData();
                formData.append("payment_ref", self.$store.getters.process);
                formData.append('payment_type', 'paybyrd');

                if (self.countStatePayment >= 6) {
                    self.countStatePayment = 0;
                    formData.append("checkstate", 1);
                }

                let options = {
                    showModalError:
                        "production" != self.$store.getters.query.env,
                };

                self.$root
                    .request(
                        "biipPayments::onFetchByRef",
                        formData,
                        self.showLoadingProgress,
                        options
                    )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((fail) => {
                        self.waiting.title = "Oops!";
                        self.waiting.message =
                            "Infelizmente, não foi possível concluir o pagamento.";
                        // interrompe cronometro de verificacao de processo
                        self.resetClocks();
                        // redefine os dados da transacao de pagamento
                        self.cancelEvent({});
                    });
            });
        },

        // requisita e interpreta o status do pagamento
        handleStateProgress: function (progress) {
            const self = this;
            if (!progress) {

                // interrompe cronometro de verificacao de processo
                // self.resetClocks();

                // nenhum processo de pagamento encontrado
                self.$router.push({ name: "payment-choice" });
            }

            // ? pagamento concluido
            if (progress.status_payment_id == 3) {
                // interrompe o timmer de processamento
                // self.resetClocks();
                // direciona para a tela de pagamento aprovado
                self.$router.push({ name: "payment-approved" });
            }

            // ? timer para a conclusao do pagamento expirado
            // if (progress.countdown == 0) {
            //     // interrompe cronometro de verificacao de processo
            //     self.resetClocks();

            //     // redefine os dados da transacao de pagamento
            //     self.$store.dispatch("setProcess", null);

            //     // redireciona o utilizador
            //     self.$router.push({ name: "payment-choice" });
            // }

            // ? status do pagamento expirado ou cancelado
            if ([4, 5].indexOf(parseInt(progress.status_payment_id)) != -1) {
                // interrompe o timer de pagamento
                // self.resetClocks();

                // redefine os dados da transacao de pagamento
                self.$store.dispatch("setProcess", null);

                //expirado
                if (parseInt(progress.status_payment_id) == 5) {
                    // self.$root.openModal("#modal-payment-expire");
                    self.progress = null;
                    // redireciona o utilizador
                    self.$router.push({ name: "payment-choice" });
                }

                // cancelados
                else {
                    // redireciona o utilizador
                    self.$router.push({ name: "payment-cancelled" });
                }
            }

            // ? pagamento devolvido (5) ou processo com errro (6)
            if ([6, 7].indexOf(parseInt(progress.status_payment_id)) != -1) {
                // interrompe o timmer de processamento
                self.resetClocks();
                // elimina os dados do processamento atual
                self.$store.dispatch("setProcess", null);
                self.progress = null;

                // armazena o erro no VUEX
                self.$store.dispatch("setError",progress.response_zsrest_states);

                // identifica o cenario
                let queryData = self.$store.getters.query;
                if ("dev" == queryData.env) {
                    // define dados para a mensagem de erro, apenas para o dev
                    // self.$root.openModal("#modal-error");
                }
            }
        },

        handleStatePayment: function (payment) {
            const self = this;
            if (!payment) {
                return;
            }

            // verifica se temos payload de dados do paybyrd
            if (
                Object.keys(payment).length > 0 &&
                Object.keys(payment).includes("transactions")
            ) {
                // verifica se ha transacoes na ordem de pagamento
                let transactions = payment.transactions;
                // transacoes vazias
                if (!Object.keys(transactions).length) {
                    return;
                }

                // ordena o array de transacoes de acordo com o index
                transactions.sort((x, y) => {
                    if (x.item > y.index) {
                        return 1;
                    }
                    if (x.item < y.index) {
                        return -1;
                    }
                    return 0;
                });
                // recupera a ultima transacao ordenada
                let lastTransaction = transactions[transactions.length - 1];

                let status = lastTransaction.status.toLowerCase();
                switch (status) {
                    case "success":
                        self.waiting.title = self.$t(
                            "payment_situation_msg.title_paid"
                        );
                        self.waiting.message = self.$t(
                            "payment_situation_msg.msg_paid"
                        );
                        self.resetClocks();
                        break;
                    case "denied":
                        self.waiting.title = self.$t(
                            "payment_situation_msg.title_fail"
                        );
                        self.waiting.message = self.$t(
                            "payment_situation_msg.msg_denied"
                        );
                        self.resetClocks();
                        self.cancelEvent({});
                        break;
                }
            }
        },

        checkProgress: function () {
            const self = this;

            // recupera da base as informacoes atualizadas
            // do status do pagamento ativo
            self.getProgress().then(function (response) {
                self.processPayment = response.data.external_payment_response;
                self.progress = response.data;

                // interpreta os dados da transacao ( que pertence a uma ordem de pagamento )
                self.handleStatePayment(self.processPayment);
                // interpreta os dados da ordem de pagamento
                self.handleStateProgress(self.progress);
            });
        },

        checkPaymentProgress: function () {
            const self = this;
            let formData = new FormData();
            self.$root
                .request("biipPayTransactions::onExecPayment", formData, false)
                .then((response) => {});
        },

        startCountdown: function () {
            const self = this;

            // iniciar um timer de 10s para chegagem de atualizacoes
            self.intervalProgress = setInterval(function () {
                // acionado para recuperar dados da transacao em espera.
                self.checkProgress();

                if (self.processPayment) {
                    // self.checkPaymentProgress();
                }
            }, 10000);
        },

        startExpiresCounter: function (seconds) {
            const self = this;
            self.expiresIn = seconds;
            // a cada segundo, o tempo de expiracao
            // da pagina e decrementado
            self.intervalExpire = setInterval(function () {
                self.expiresIn > 0 ? self.expiresIn-- : 0;
            }, 1000);
        },

        // ? define o tempo em que o ecra aguardará interacao com a tela.
        setPaymentTimeout: function () {
            const self = this;

            // apagar o  contador anterior
            clearTimeout(self.intervalToConcluded);

            // inicia contador de expiracao.
            // prazo de 1,5 min ( 90s );
            self.startExpiresCounter(90);

            // timer de 1,5 minutos, para que o utlizador prossiga com o pagamento
            // expirado o prazo, a aplicacao cancelara a ordem
            self.intervalToConcluded = setTimeout(function () {

                // remove timmer de expiracao
                clearInterval(self.intervalExpire);            

                // e necessario cancelar o process ativo na PAYBYRD
                let cancelConfigs = {};

                // direciona utilizador para uma pagina
                cancelConfigs.redirect = "payment-cancelled";                

                // cancela a transacao no backoffice e redireciona
                self.cancelEvent(cancelConfigs);
            }, 90000);
        },

        // ? cancela a transacao no backoffice do biip
        cancelEvent: async function (configs = {}) {
            const self = this;
            let formData = new FormData();
            // envia o numero do processo a ser cancelada            
            formData.append("payment_ref", self.$store.getters.process);
            formData.append('payment_type', 'paybyrd');
            // aciona o cancelamento da transacao
            let resp = await self.$root
                .request("biipPayments::onCancelByRef", formData)
                .then((response) => {
                    // reseta o processo de pagamento atual no VUEX
                    self.$store.dispatch("setProcess", null);
                    self.progress = null;
                    // redireciona para a tela de escolha do metodo de pagamento
                    if (Object.keys(configs).length) {                        
                        if (configs.redirect.length) {
                            self.$router.push({ name: configs.redirect });
                        }
                    }
                });
        },

        // ? reseta todos os timmers da pagina
        resetClocks: function () {
            // remove todos os timers da view;
            const self = this;
            clearInterval(self.intervalProgress);
            clearInterval(self.intervalExpire);            
            clearTimeout(self.intervalToConcluded);
        },
    },
    data: function () {
        return {
            waiting: {
                title: this.$t("payment_situation_msg.title_waiting"),
                message: this.$t("payment_situation_msg.msg_waiting"),
            },            
            intervalProgress: null,
            intervalExpire: null,
            intervalToConcluded: null,
            expiresIn: 0,
            timecountdown: 0,
            progress: null,
            processPayment: null,
            showLoadingProgress: false,
            countStatePayment: 0,
            table: {
                total: 0,
            },
        };
    },
    beforeDestroy() {
        const self = this;
        // remove todos os timmers quando a pagina de pagamento e abandonada
        self.resetClocks();
    },
};
</script>

<style lang="scss" scoped>
.content-payment-failure {
    background: #eaeaea;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    .content-payment-itens {
        height: 100%;
    }
    .content-text-element h2,
    .content-text-element p {
        color: #132148;
    }
    .content-text-btn-close {
        background: #132148;
        border: 1px solid #132148;
    }
}
</style>
