import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const states = {
	isLoading: 0,
	url: sessionStorage.getItem('biip_query'),
  locale: sessionStorage.getItem('biip_locale'),  
  geolocation: sessionStorage.getItem('biip_geo'),
  session: localStorage.getItem('biip_session'),
  process: localStorage.getItem('biip_process'),
  billing: sessionStorage.getItem('biip_billing'),
  responseRequest: {},
  error: '',
};

const getters = {
  session: state => {
    return state.session;
  },
  process: state => {
    return state.process;
  },
  billing: state => {
    return state.billing ? JSON.parse(state.billing) : {};
  },
  error: state => {
    return state.error;
  },
  query: state => {    
    return state.url ? JSON.parse(state.url) : {};
  },
  geolocation: state => {    
    return state.geolocation ? JSON.parse(state.geolocation) : {};
  },
  isLoading: state => {
    return state.isLoading;
  },
  locale: state => {
    return state.locale;
  },
  getReponse: state => {
    return state.responseRequest;
  }
};

const mutations = {
  setSession(state, value){
    localStorage.setItem('biip_session', value);
    state.session = value;
  },
  setProcess(state, value){
    localStorage.setItem('biip_process', value);
    state.process = value;
  },
  setBilling(state, value) {    
    sessionStorage.setItem('biip_billing', value);
    state.billing = value;
  },
	updateUrl(state, value) {    
      sessionStorage.setItem('biip_query', value);
      state.url = value;
  },
  setGeolocation(state, value) {    
      sessionStorage.setItem('biip_geo', value);
      state.geolocation = value;
  },
  loading(state, value){
    state.isLoading = value;
  },
  setLocale(state, value){
    sessionStorage.setItem('biip_locale', value)
    state.locale = value;
  },
  request(state, value){
    state.responseRequest = value;
  },
  setError(state, value){
    state.error = value;
  }
}

const actions = {
	updateUrl({commit}, value) {		
    let json = JSON.stringify(value);    
		commit('updateUrl', json);
	},
  setBilling({commit}, value) {		
    let json = JSON.stringify(value);    
		commit('setBilling', json);
	},
  setGeolocation({commit}, value) {    
    let json = JSON.stringify(value);    
    commit('setGeolocation', json);
  },
  loading({commit}, value){
    commit('loading', value);
  },
  setSession({commit}, value){
    let json = btoa(JSON.stringify(value));
    commit('setSession', json);
  },
  setProcess({commit}, value){    
    commit('setProcess', value);
  },
  setLocale({commit}, value){
    commit('setLocale', value);
  },  
  request({commit}, value){
    commit("request", value)
  },
  setError({commit}, value){
    commit("setError", value)
  }
}

export default new Vuex.Store({
  state: states,
  mutations: mutations,
  actions: actions,
  getters: getters
})
