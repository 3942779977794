import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Payment from "../views/Payment.vue";
import PaymentPaybyrd from "../views/PaymentPaybyrd.vue";
// import PaymentPaybyrdFailure from "../views/PaymentPaybyrdFailure.vue";
import PaymentPaybyrdRedirect from "../views/PaymentPaybyrdRedirect.vue";
import PaymentPaybyrdApple from "../views/PaymentPaybyrdApple.vue";
import PaymentApproved from "../views/PaymentApproved.vue";
import PaymentCancelled from "../views/PaymentCancelled.vue";
import Page404 from '../views/404.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/payment/:id",
        name: "payment-choice",
        component: Payment,
    },
    {
        path: "/payment-paybyrd",
        name: "payment-paybyrd",
        component: PaymentPaybyrd,
    },
    {
        path: '/payment-paybyrd-redirect',
        name: 'payment-paybyrd-redirect',
        component: PaymentPaybyrdRedirect,        
    },
    {
        path: '/payment-paybyrd-apple',
        name: 'payment-paybyrd-apple',
        component: PaymentPaybyrdApple,        
    },    
    {
        path: '/payment-approved',
        name: 'payment-approved',
        component: PaymentApproved,        
    }, 
    {
        path: '/payment-cancelled',
        name: 'payment-cancelled',
        component: PaymentCancelled,        
    },
    // {
    //     path: '/payment-paybyrd-failure',
    //     name: 'payment-paybyrd-failure',
    //     component: PaymentPaybyrdFailure,
    //     beforeEnter(to, from, next) {}
    // },    
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
        path: '/404',
        name: 'Page404',
        component: Page404
    },
    {
        path: '*',
        redirect: '/404'
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

export default router;
