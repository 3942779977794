<template>
  <div class="content-loading">
    <div class="backdrop-loader">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.content-loading {  
  .backdrop-loader {
    position: fixed;
    top: 140px;
    left:0;
    height: calc(100% - 140px);
    width: 100%;
    z-index: 1020;
    background-color: #00205C;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    .loader {
      border: 16px solid #fff;
      border-top: 16px solid #43aafd;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}
</style>