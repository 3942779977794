<template>
  <div>
    <main class="payment">           
      <section class="content-payment-failure">
        <div class="content-payment-itens container">          
          <!--- IFRAME -->
          <template v-if="transaction && transaction.external_payment_token">         
            <form target="paybyrdFrame" id="paybyrdForm" action="https://gateway.paybyrd.com/v1/hostedform" method="post">
              <input type="hidden" name="orderId" :value="transaction.external_payment_token"/>
              <input type="hidden" name="redirectUrl" :value="fetchUrl('payment-paybyrd-redirect')"/>
              <input type="hidden" name="failureRedirectUrl" :value="fetchUrl('payment-paybyrd-failure')"/>
              <input type="hidden" name="culture" value="pt-PT" />
              <input type="hidden" name="showCancelButton" value="false" />
            </form>
    
            <iFrame class="iframe-paybyrd" name="paybyrdFrame" id="paybyrdFrame" scrolling="no"></iFrame>            
          </template>                           
          <!--- ./IFRAME -->
        </div>
      </section>
    </main>    
  </div>
</template>

<script>

export default {
  name: "payment-paybyrd",
  mounted() {        
    // configura no VUEX a forma de pagamento da transacao: paybyrd
    this.setupMethod();
    this.fetchPayment();
  },
  updated: function () {
    this.$nextTick(function () {
        // vizualizacao do frame da PAYBYRD
        this.initFrame();
    })
  },
  methods: {
    // ? atualizacao da forma de pagamento escolhida pelo cliente    
    setupMethod: function(){
      const self = this;
      // recupera os dados de facturacao do VUEX      
      let billingData = self.$store.getters.billing ?? {};      
      // define a forma de pagamento paybyrd ( 4 ) no VUEX
      billingData['payment_type_id'] = 4;
      // atualiza os dados de facturacao do VUEX
      self.$store.dispatch("setBilling", billingData);      
    }, 

    // ? renderiza o ifram do Paybyrd
    initFrame: async function(){      
      const self = this; 
      if(self.transaction && Object.keys(self.transaction).length && self.transaction.status_payment_id == 1){                  
        document.getElementById("paybyrdForm").submit();      
      }            
    },  

    // ? recupera os dados da transacao
    fetchPayment: function(){
        const self = this;
        let form = new FormData;
        form.append('payment_type', 'paybyrd');
        form.append('orderid', self.$store.getters.query.orderid ?? null);
        form.append('client_doc', self.$store.getters.billing.doc ?? null);
        form.append('client_terms', self.$store.getters.billing.accept_terms ?? null);
        form.append('client_email', self.$store.getters.billing.email ?? null);
        form.append('client_name', self.$store.getters.billing.name ?? null);
        form.append('client_phone', self.$store.getters.billing.phone ?? null);
        self.$root.request('biipPayments::onCreate', form, {loading: false})
        .then( response => { 
            if( response.success ){
                self.transaction = response.data;
            }
        })
        .catch( function(error){
          console.log('error');
          console.log(error);
        });
    }, 

    // ? define as urls de redirects para o iframe da PAYBYRD
    fetchUrl: function(sufix){
      return location.origin + '/' + sufix;
    },

    // ? cancela a transacao no backoffice do biip
    cancelEvent: async function (configs = {}) {
        const self = this;
        let formData = new FormData();
        // envia o numero do processo a ser cancelada            
        formData.append("payment_ref", self.$store.getters.process);
        formData.append('payment_type', 'paybyrd');
        // aciona o cancelamento da transacao
        let resp = await self.$root
            .request("biipPayments::onCancelByRef", formData)
            .then((response) => {
                // reseta o processo de pagamento atual no VUEX
                self.$store.dispatch("setProcess", null);
                self.progress = null;
                // redireciona para a tela de escolha do metodo de pagamento
                if (Object.keys(configs).length) {
                    if (configs.unblock) {
                        self.setTableUnblocked();
                    }

                    if (configs.redirect.length) {
                        self.$router.push({ name: configs.redirect });
                    }
                }
            });
    },    
  },
  data: function () {
    return {
      transaction: {}
    };
  },
  beforeDestroy() {}  
};
</script>

<style lang="scss" scoped>
.content-payment-failure {

    background:#eaeaea;
    // position: fixed;
    // width: 100%;
    // height: 100%;
    overflow: auto;
    .content-payment-itens{
      height: 100%;
    }
}
.iframe-paybyrd {
  width: 1px !important;
  min-width: 100% !important;
  *width: 100% !important;
  height: 800px; 
  margin: 0; 
  border: 0;
}
</style>
