<template>
  <div class="home">
    <div class="content-home">
      <Header v-bind:display="header.show" />
      <generic-notification v-bind:notification="notification" />
    </div>
    <modal-terms />
  </div>
</template>

<script>
import ModalTerms from '../components/modals/ModalTerms.vue';
import Header from "@/components/header/Header.vue";
import GenericNotification from '@/components/GenericNotification.vue';

export default {
  name: "Home",
  data() {
    return {
      header:{ 
        show:true
      },
      notification:{
          type: "info",
          title: "Biip Pay",
          msg: ""
      }      
    };
  },
  components: {
    ModalTerms,
    Header,        
    GenericNotification
  }
};
</script>
<style lang="scss">
.content-home {
  &-banner {
    &-bg {
      width: 100%;
      height: 140px;
      background: url('~@/assets/img/home/image-banner.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &-text {
      padding: 15px 30px;
      background: #5CA3D8;
      text-align: left;
      p {
        font-size: 15px;
        font-family: 'Font Roman';
        color: var(--white);
        margin-bottom: 0;
      }
    }
  }
  .content-table {
    padding: 30px 30px 45px 30px;
    &-itens {
      padding: 0;
      &-header {
        text-align: left;
        margin-bottom: 15px;
        h2 {
          font-size: 24px;
          font-family: 'Font Black';
          color: var(--blue-dark);
          margin-bottom: 0;
        }
      }
      &-body {
        .content-elements {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 0px;
          &-div {
            display: flex;
            align-items: center;
            p {
              font-size: 18px;
              font-family: 'Font Roman';
              color: var(--blue-dark);
              margin-bottom: 0;
              line-height: 1.2;
              text-align: left;
            }
            .content-elements-quantity {
              font-size: 12px;
              font-family: 'Font Black';
              color: #5CA3D8;
              margin-right: 14px;
              margin-bottom: 0;
            }
            .content-elements-description {
              font-size: 12px;
              color: #707070;
            }
            input {
              font-size: 12px;
              font-family: 'Font Black';
              color: #5CA3D8;
              border: 0;
              background: 0;
              width: 20px;
              padding: 0;
            }
          }
          &-piece {
            display: flex;
            align-items: center;
            .number {
              display: flex;
              align-items: center;
              .minus-table, .plus-table {
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: var(--white);
                border: 1px solid #E5E5E5;
                border-radius: 4px;
                margin-right: 10px;
              }
            }
            p {
              font-size: 18px;
              font-family: 'Font Medium';
              color: #5CA3D8;
              margin-bottom: 0;
            }
          }
          &-amout {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-top: 25px;
            border-top: 1px solid #DFDFDF;
            p {
              font-size: 18px;
              font-family: 'Font Black';
              color: var(--blue-dark);
              margin-bottom: 0;
              line-height: 1;
              &:not(:first-child){
                font-size: 24px;
                font-family: 'Font Black';
                color: #5CA3D8;
              }
            }
          }
        }
      }
    }
  }
  .content-payment {
    padding: 0px 30px 70px 30px;
    &-itens {
      padding: 0;
      &-header {
        text-align: left;
        margin-bottom: 30px;
        h2 {
          font-size: 24px;
          font-family: 'Font Black';
          color: var(--blue-dark);
          transition: 500ms;
          line-height: 1.2;
          @media (max-width: 359px){
            font-size: 24px;
          }
        }
      }
      &-body {
        .content-element {
          &:first-child{
            margin-bottom: 60px;
          }
          &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            p {
              font-size: 24px;
              font-family: 'Font Black';
              color: var(--blue-dark);
              line-height: 1;
              margin-bottom: 0;
              span {
                font-family: 'Font Book';
              }
            }
          }
          .content-inputs {
            position: relative;
            margin-bottom: 20px;
            label {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 20px;
              font-size: 18px;
              font-family: 'Font Book';
              color: var(--blue-dark);
              margin-bottom: 0;
            }
            .input-telemovel {
              padding-left: 80px;
            }
            input {
              height: 55px;
              width: 100%;
              border-radius: 10px;
              border: 0;
              box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
              padding-left: 20px;
              font-size: 18px;
              font-family: 'Font Book';
              color: var(--blue-dark);
              outline: none;
              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #ACACAC;
              }
              &::-moz-placeholder { /* Firefox 19+ */
                color: #ACACAC;
              }
              &:-ms-input-placeholder { /* IE 10+ */
                color: #ACACAC;
              }
              &:-moz-placeholder { /* Firefox 18- */
                color: #ACACAC;
              }
            }
            &-select {
              position: relative;
              select {
                height: 55px;
                width: 100%;
                border-radius: 10px;
                border: 0;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                padding-left: 20px;
                font-size: 18px;
                font-family: 'Font Book';
                color: var(--blue-dark);
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                outline: none;
              }
              svg {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
              }
            }
            &-checkbox {
              display: flex;
              align-items: center;
              & > input[type="checkbox"]:checked:before {
								content: "";
								height: 100%;
								width: 100%;
								background: url("~@/assets/img/check.png");
								background-position: center !important;
								background-repeat: no-repeat !important;
								background-color: var(--white);
							}
							& > input[type="checkbox"]:before {
								content: "";
								position: absolute;
								top: 0;
								left: 0;
								bottom: 0;
								right: 0;
								background-color: var(--white);
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 3px;
								border: 0;
								box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
							}
							& > input[type="checkbox"] {
								position: relative;
								height: 23px;
								width: 23px;
								border-radius: 3px;
								-webkit-appearance: none;
								-moz-appearance: none;
								appearance: none;
								outline: 0 !important;
								margin: 0;
							}
              label {
                font-size: 18px;
                font-family: 'Font Book';
                color: var(--blue-dark);
                padding-top: 3px;
                margin-left: 15px;
                margin-bottom: 0;
                a {
                  color: var(--blue-dark) !important;
                  text-decoration: underline !important;
                  text-decoration-color: var(--blue-dark) !important;
                  transition: 500ms;
                  &:hover,&:focus {
                    cursor: pointer;
                    opacity: 0.8;
                  }
                }
              }
            }
          }
          &-account {
            padding: 25px 20px;
            background: var(--white);
            border: 1px solid #ECF7FF;
            border-radius: 10px;
            margin-bottom: 50px;
            &-itens {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 22px;
              p {
                font-size: 16px;
                font-family: 'Font Book';
                color: #707070;
                margin-bottom: 0;
              }
            }
            &-total {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-top: 20px;
              border-top: 1px solid #ECF7FF;
              p {
                font-size: 16px;
                font-family: 'Font Black';
                color: #132148;
                margin-bottom: 0;
              }
            }
          }
          &-btns {
            .btn-mbway {
              height: 80px;
              width: 100%;
              background: var(--white);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              border: 0;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              margin-bottom: 25px;
              transition: 500ms;
              &:hover,&:focus {
                cursor: pointer;
                opacity: 0.8;
              }
            }
            .btn-visa-mastercard {
              height: 80px;
              width: 100%;
              background: var(--white);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              border: 0;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              margin-bottom: 30px;
              transition: 500ms;
              &:hover,&:focus {
                cursor: pointer;
                opacity: 0.8;
              }
              img {
                max-height: 40px;
              }
            }
            &-text {
              font-size: 14px;
              font-family: 'Font Medium';
              color: var(--blue-dark);
              text-align: left;
              line-height: 26px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>