<template>
<!---->
    <div class="home">
        <div class="content-home">
            <Header v-bind:display="header.show" />
            <template v-if="listItens.length">
                <div class="content-table">
                    <div class="content-table-itens container">
                        <div class="content-table-itens-header">
                            <h2>A sua conta</h2>
                        </div>
                        <!-- DIV PARA QUANDO TIVER PEDIDO -->
                        <template v-if="Object.keys(listItens).length">
                            <div class="content-table-itens-body">
                                <div
                                    class="content-elements"
                                    v-for="(item, k) in listItens"
                                    :key="k"
                                >
                                    <div class="content-elements-div">
                                        <p class="content-elements-quantity">
                                            {{ item.qtd }}
                                        </p>
                                        <div>
                                            <p class="content-elements-name">
                                                {{ item.product }}
                                            </p>
                                            <p
                                                class="content-elements-description"
                                            ></p>
                                        </div>
                                    </div>
                                    <div class="content-elements-piece">
                                        <p>{{ item.price_unit | money }}</p>
                                    </div>
                                </div>

                                <div class="content-elements">
                                    <div class="content-elements-amout">
                                        <p>Total</p>
                                        <p>{{ listTotal | money }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <!-- DIV PARA QUANDO NÃO TIVER CONSUMO -->
                        <template v-else>                    
                        <div class="content-table-itens-body">
                            <div class="content-elements">
                                <div class="content-elements-div">
                                    <p>Sem consumo registrado</p>
                                </div>
                            </div>
                        </div>
                        </template>
                    </div>
                </div>

                <div class="content-payment">
                    <div class="content-payment-itens container">
                        <form class="content-payment-itens-body" autocomplete="off">
                            <div class="content-element">
                                <div class="content-element-header">
                                    <p>Os seus dados</p>
                                </div>
                                <div class="content-inputs">
                                    <input
                                        class="inputs-default"
                                        v-model="billing.name"
                                        type="text"
                                        name="name"
                                        value=""
                                        placeholder="Nome completo (opcional)"
                                    />
                                </div>
                                <div class="content-inputs">
                                    <input
                                        class="inputs-default"
                                        v-model="billing.doc"
                                        type="text"
                                        name="nif-nipc"
                                        value=""
                                        placeholder="NIF/NIPC (opcional)"
                                    />
                                </div>
                                <div class="content-inputs">
                                    <input
                                        class="inputs-default"
                                        v-model="billing.email"
                                        type="email"
                                        name="email"
                                        value=""
                                        placeholder="Email Fatura Digital (opcional)"
                                    />
                                </div>
                                <div class="content-inputs">
                                    <label>+351</label>
                                    <input
                                        class="inputs-default input-telemovel"
                                        type="text"
                                        v-model="billing.phone"
                                        name="telemovel"
                                        value=""
                                        placeholder="Nº de telemóvel"
                                        required
                                    />
                                </div>
                                <div class="content-inputs-checkbox">
                                    <input
                                        v-model="billing.accept_terms"
                                        type="checkbox"
                                        value=""
                                        name="terms"
                                        required
                                    />
                                    <label
                                        >Aceitar
                                        <a
                                            @click="
                                                $bvModal.show(
                                                    'modal-terms-conditions'
                                                )
                                            "
                                            >Termos e Condições</a
                                        ></label
                                    >
                                </div>
                            </div>

                            <div class="content-element" v-if="billing.accept_terms === true">
                                <div class="content-element-header">
                                    <p>Meio de pagamento</p>
                                </div>
                                <div class="content-element-btns">
                                    <button class="btn-mbway" v-if="availablePaymentsMethods.includes('mbway')" @click="initPayment('payment-mbway')">
                                        <img
                                            class="img-fluid"
                                            src="@/assets/img/logos/logo-mbway.png"
                                            alt="logo mbway"
                                            title="logo mbway"
                                        />
                                    </button>
                                    <button class="btn-visa-mastercard" v-if="availablePaymentsMethods.includes('paybyrd')" @click="initPayment('payment-paybyrd')">
                                        <img
                                            class="img-fluid"
                                            src="@/assets/img/logos/logo-visa-mastercard.png"
                                            alt="logo visa and mastercard"
                                            title="logo visa and mastercard"
                                        />
                                    </button>
                                    <p class="content-element-btns-text">
                                        Os dados recolhidos servem apenas para
                                        completar a transação e não são utilizados
                                        para outros efeitos, salvo se indicado pelo
                                        cliente.
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
            <template v-else>
                <generic-notification v-bind:notification="notification" />
            </template>
        </div>
        <modal-terms />  
        <modal-error/>      
    </div>
</template>

<script>
import ModalTerms from "@/components/modals/ModalTerms.vue";
import ModalError from "@/components/modals/ModalError.vue";
import Header from "@/components/header/Header.vue";
import GenericNotification from '@/components/GenericNotification.vue';

export default {
    name: "Payment",
    mounted() {                  
        this.getOrderId();
        this.fetchBillingData();
    },
    data() {
        return {
            header:{ 
                show:true
            },
            listItens: [],
            listTotal: 0,
            availablePaymentsMethods:[],
            notification:{
                type: "",
                title: "",
                msg: ""
            },
            billing: {
              name: "",
              phone: "",
              email: "",
              accept_terms: false,
              doc: ""
            }
        };
    },
    methods: {
        // identifica o orderid na url
        getOrderId: function () {
            let params = this.$route.params;
            if (params && Object.keys(params).includes("id")) {
                this.$store.dispatch("updateUrl", { orderid: params.id });
                this.fetchOrder(params.id);
            } else {
                this.$router.push({ name: "about" });
            }
        },

        // recupera os dados da ordem
        fetchOrder: async function (id) {
            let form = new FormData();
            form.append("orderid", id);
            let order = await this.$root
                .request("biipOrders::onFetch", form)
                .then((response) => {
                    if (response.success) {
                        this.listItens = response.data.list;
                        this.listTotal = response.data.total;
                        this.availablePaymentsMethods = response.data.paymethods;
                        if(response.data.sourceid){
                            let qryStorage = this.$store.getters.query;
                            qryStorage['sourceid'] = response.data.sourceid;
                            this.$store.dispatch("updateUrl", qryStorage);
                        }                        
                    }
                    else{
                        // this.header.show = false;
                        this.notification = response.notification;
                    }
                });
        },

        initPayment(route) {
          const self = this;

          // se informados, salva os dados de faturacao ( nome, email, nif) na cache do vue
          if (self.billing && Object.keys(self.billing).length) {                
            // salva os dados de faturamento do VUEX
            self.$store.dispatch("setBilling", self.billing);
          }

          // teste para iphones
          if(self.$route.query && self.$route.query.apple){
            route = 'payment-paybyrd-apple';
          }

          // redireciona
          self.$router.push({ name: route });
        },

        fetchBillingData() {
          const self = this;

          // recupera os dados de cobranca, se armazenados
          let billingData = self.$store.getters.billing;

          // se houver dados no storage, recupera para o vue
          if (billingData) {
            self.billing.name = billingData.name;
            self.billing.email = billingData.email;
            self.billing.doc = billingData.doc;
            self.billing.phone = billingData.phone;
            self.billing.accept_terms = billingData.accept_terms;
          }

          return;
        }        
    },
    components: {
        ModalTerms,
        ModalError,
        Header,        
        GenericNotification
    },
};
</script>
<style lang="scss">
.content-home {
    &-banner {
        &-bg {
            width: 100%;
            height: 140px;
            background: url("~@/assets/img/home/image-banner.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        &-text {
            padding: 15px 30px;
            background: #5ca3d8;
            text-align: left;
            p {
                font-size: 15px;
                font-family: "Font Roman";
                color: var(--white);
                margin-bottom: 0;
            }
        }
    }
    .content-table {
        padding: 30px 30px 45px 30px;
        &-itens {
            padding: 0;
            &-header {
                text-align: left;
                margin-bottom: 15px;
                h2 {
                    font-size: 24px;
                    font-family: "Font Black";
                    color: var(--blue-dark);
                    margin-bottom: 0;
                }
            }
            &-body {
                .content-elements {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 0px;
                    &-div {
                        display: flex;
                        align-items: center;
                        p {
                            font-size: 18px;
                            font-family: "Font Roman";
                            color: var(--blue-dark);
                            margin-bottom: 0;
                            line-height: 1.2;
                            text-align: left;
                        }
                        .content-elements-quantity {
                            font-size: 12px;
                            font-family: "Font Black";
                            color: #5ca3d8;
                            margin-right: 14px;
                            margin-bottom: 0;
                        }
                        .content-elements-description {
                            font-size: 12px;
                            color: #707070;
                        }
                        input {
                            font-size: 12px;
                            font-family: "Font Black";
                            color: #5ca3d8;
                            border: 0;
                            background: 0;
                            width: 20px;
                            padding: 0;
                        }
                    }
                    &-piece {
                        display: flex;
                        align-items: center;
                        .number {
                            display: flex;
                            align-items: center;
                            .minus-table,
                            .plus-table {
                                height: 20px;
                                width: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: var(--white);
                                border: 1px solid #e5e5e5;
                                border-radius: 4px;
                                margin-right: 10px;
                            }
                        }
                        p {
                            font-size: 18px;
                            font-family: "Font Medium";
                            color: #5ca3d8;
                            margin-bottom: 0;
                        }
                    }
                    &-amout {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding-top: 25px;
                        border-top: 1px solid #dfdfdf;
                        p {
                            font-size: 18px;
                            font-family: "Font Black";
                            color: var(--blue-dark);
                            margin-bottom: 0;
                            line-height: 1;
                            &:not(:first-child) {
                                font-size: 24px;
                                font-family: "Font Black";
                                color: #5ca3d8;
                            }
                        }
                    }
                }
            }
        }
    }
    .content-payment {
        padding: 0px 30px 70px 30px;
        &-itens {
            padding: 0;
            &-header {
                text-align: left;
                margin-bottom: 30px;
                h2 {
                    font-size: 24px;
                    font-family: "Font Black";
                    color: var(--blue-dark);
                    transition: 500ms;
                    line-height: 1.2;
                    @media (max-width: 359px) {
                        font-size: 24px;
                    }
                }
            }
            &-body {
                .content-element {
                    &:first-child {
                        margin-bottom: 60px;
                    }
                    &-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 30px;
                        p {
                            font-size: 24px;
                            font-family: "Font Black";
                            color: var(--blue-dark);
                            line-height: 1;
                            margin-bottom: 0;
                            span {
                                font-family: "Font Book";
                            }
                        }
                    }
                    .content-inputs {
                        position: relative;
                        margin-bottom: 20px;
                        label {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 20px;
                            font-size: 18px;
                            font-family: "Font Book";
                            color: var(--blue-dark);
                            margin-bottom: 0;
                        }
                        .input-telemovel {
                            padding-left: 80px;
                        }
                        input {
                            height: 55px;
                            width: 100%;
                            border-radius: 10px;
                            border: 0;
                            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                            padding-left: 20px;
                            font-size: 18px;
                            font-family: "Font Book";
                            color: var(--blue-dark);
                            outline: none;
                            &::-webkit-input-placeholder {
                                /* Chrome/Opera/Safari */
                                color: #acacac;
                            }
                            &::-moz-placeholder {
                                /* Firefox 19+ */
                                color: #acacac;
                            }
                            &:-ms-input-placeholder {
                                /* IE 10+ */
                                color: #acacac;
                            }
                            &:-moz-placeholder {
                                /* Firefox 18- */
                                color: #acacac;
                            }
                        }
                        &-select {
                            position: relative;
                            select {
                                height: 55px;
                                width: 100%;
                                border-radius: 10px;
                                border: 0;
                                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                                padding-left: 20px;
                                font-size: 18px;
                                font-family: "Font Book";
                                color: var(--blue-dark);
                                appearance: none;
                                -moz-appearance: none;
                                -webkit-appearance: none;
                                outline: none;
                            }
                            svg {
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                transform: translateY(-50%);
                                pointer-events: none;
                            }
                        }
                        &-checkbox {
                            display: flex;
                            align-items: center;
                            & > input[type="checkbox"]:checked:before {
                                content: "";
                                height: 100%;
                                width: 100%;
                                background: url("~@/assets/img/check.png");
                                background-position: center !important;
                                background-repeat: no-repeat !important;
                                background-color: var(--white);
                            }
                            & > input[type="checkbox"]:before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                background-color: var(--white);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 3px;
                                border: 0;
                                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
                            }
                            & > input[type="checkbox"] {
                                position: relative;
                                height: 23px;
                                width: 23px;
                                border-radius: 3px;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                outline: 0 !important;
                                margin: 0;
                            }
                            label {
                                font-size: 18px;
                                font-family: "Font Book";
                                color: var(--blue-dark);
                                padding-top: 3px;
                                margin-left: 15px;
                                margin-bottom: 0;
                                @media (max-width: 359px){
                                    font-size: 16px;
                                }
                                a {
                                    color: var(--blue-dark) !important;
                                    text-decoration: underline !important;
                                    text-decoration-color: var(
                                        --blue-dark
                                    ) !important;
                                    transition: 500ms;
                                    &:hover,
                                    &:focus {
                                        cursor: pointer;
                                        opacity: 0.8;
                                    }
                                }
                            }
                        }
                    }
                    &-account {
                        padding: 25px 20px;
                        background: var(--white);
                        border: 1px solid #ecf7ff;
                        border-radius: 10px;
                        margin-bottom: 50px;
                        &-itens {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 22px;
                            p {
                                font-size: 16px;
                                font-family: "Font Book";
                                color: #707070;
                                margin-bottom: 0;
                            }
                        }
                        &-total {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-top: 20px;
                            border-top: 1px solid #ecf7ff;
                            p {
                                font-size: 16px;
                                font-family: "Font Black";
                                color: #132148;
                                margin-bottom: 0;
                            }
                        }
                    }
                    &-btns {
                        .btn-mbway {
                            height: 80px;
                            width: 100%;
                            background: var(--white);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 10px;
                            border: 0;
                            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                            margin-bottom: 25px;
                            transition: 500ms;
                            &:hover,
                            &:focus {
                                cursor: pointer;
                                opacity: 0.8;
                            }
                        }
                        .btn-visa-mastercard {
                            height: 80px;
                            width: 100%;
                            background: var(--white);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 10px;
                            border: 0;
                            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                            margin-bottom: 30px;
                            transition: 500ms;
                            &:hover,
                            &:focus {
                                cursor: pointer;
                                opacity: 0.8;
                            }
                            img {
                                max-height: 40px;
                            }
                        }
                        &-text {
                            font-size: 14px;
                            font-family: "Font Medium";
                            color: var(--blue-dark);
                            text-align: left;
                            line-height: 26px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
